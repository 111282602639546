import { useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import Link from "next/link";
import { SiteContext } from "../Contexts/SiteContext";

const SearchModal = dynamic(import("./SearchModal"));

const ProductHeader = props => {
	const { nav, logo } = props;
	const [isActive, setIsActive] = useState(-1);
	const [isOpen, setIsOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);

	const dropdown = {
		enter: {
			height: "auto",
			overflow: "visible"
		},
		exit: {
			height: 0,
			overflow: "hidden"
		}
	};

	const NavItem = (props) => {
		const { link, enableLine } = props;

		switch (link.type) {
			case 'Entry':
				return (
					<Link href={`/${link.uri}`}>
						<a
							className="navItem"
							title={link.title}
							target={
								link.newWindow
									? "_blank"
									: "_self"
							}
						>
							{link.title}
							{enableLine && <div className="line"></div>}
						</a>
					</Link>
				)
			case 'Custom':
				return (
					<a className="navItem" title={link.title} href={link.url} target={link.target}>
						{link.title}
						{enableLine && <div className="line"></div>}
					</a>
				)
		}
	}

	
	const finalLogo = logo || "/static/img/logo.svg";

	return (
		<SiteContext.Consumer>
			{({ toggleSideMenu }) => (
				<Fragment>
					<div className="navbar-fixed productNav">
						{/* Top Navbar Desktop */}
						<nav>
							<div className={`nav-wrapper ${isOpen ? "dark" : ""}`}>
								<Link href={`/`}>
									<a
										className="brand-logo left"
										title="Honda - South Africa"
									>
										<img
											src={finalLogo}
											title="Honda - South Africa"
											alt="Honda - South Africa"
										/>
									</a>
								</Link>
								<a
									href="#"
									data-target="mobile-demoo"
									className="sidenav-trigger right"
									onClick={() => {
										// setIsOpen(true);
										toggleSideMenu(!isOpen);
										setIsOpen(!isOpen);
									}
									}
									title="Menu"
								>
									{isOpen ?
										<FontAwesomeIcon icon={["far", "times"]} size="lg" />
										:
										<FontAwesomeIcon icon={["far", "bars"]} size="lg" />
									}
								</a>
								<ul className="right hide-on-med-and-down navBlock">
									{nav && nav.map((n, index) => {
										const hasChildren = n.hasOwnProperty('children') && n.children.length > 0;
										return (
											<li
												key={n.id}
												className={`top_navbar_list navBlockItem valign-wrapper ${hasChildren ? "navDropdown" : ""
													}`}
												onMouseEnter={() => {
													if (hasChildren) {
														setIsActive(index);
													}
												}}
												onMouseLeave={() => {
													if (hasChildren) {
														setIsActive(-1);
													}
												}}
												onClick={() => {
													if (hasChildren) {
														if (isActive === -1) {
															setIsActive(index);
														} else {
															setIsActive(-1);
														}
													}
												}}
											>
												{n.type == "Group" && hasChildren ? (
													<Fragment>
														{n.title}{" "}
														{isActive === index ? (
															<FontAwesomeIcon
																icon={[
																	"far",
																	"angle-up"
																]}
																size="2x"
																className={"ml10px"}
															/>
														) : (
															<FontAwesomeIcon
																icon={[
																	"far",
																	"angle-down"
																]}
																size="2x"
																className={"ml10px"}
															/>
														)}
														<motion.ul
															initial="exit"
															className="dropdownList"
															animate={
																isActive === index
																	? "enter"
																	: "exit"
															}
															variants={dropdown}
														>
															{n.children.map((li, i) => {
																return (
																	<li
																		key={`child-${li.id}`}
																	>
																		<NavItem link={li} enableLine={n.children.length - 1 != i} />
																	</li>
																);
															})}
														</motion.ul>
													</Fragment>
												) : (
													<NavItem link={n} />
												)}
											</li>
										);
									})}

									<li
										key="search"
										className="top_navbar_list navBlockItem"
										onClick={() => {
											setSearchOpen(true);
										}}
									>
										<FontAwesomeIcon
											icon={["far", "search"]}
											size="1x"
										/>
									</li>
								</ul>
							</div>
						</nav>
						{/* Open Side Navbar Mobile */}
						<div
							className="openSideNavMobile"
							style={{ visibility: `${isOpen ? "visible" : "hidden"}` }}
							onClick={() => {
								setIsOpen(false);
								toggleSideMenu(false);
							}}
						>
							<ul
								className={
									isOpen
										? "openSideNav sidenav"
										: "closeSideNav sidenav"
								}
								onClick={e => e.stopPropagation()}
							>
								<li
									key="search"
									className="top_navbar_list navBlockItem"
									onClick={() => {
										setSearchOpen(true);
									}}
								>
									<FontAwesomeIcon
										icon={["fas", "search"]}
										size="1x"
									/>
								</li>
								{nav && nav.map((n, index) => {
									const hasChildren = n.hasOwnProperty('children') && n.children.length > 0;

									return (
										<li
											key={`mobile-${n.id}`}
											onClick={() => {
												if (hasChildren) {
													if (isActive === -1) {
														setIsActive(index);
													} else {
														setIsActive(-1);
													}
												}
											}}
										>
											{hasChildren ? (
												<Fragment>
													{n.title + "\t"}
													{isActive === index ? (
														<FontAwesomeIcon
															icon={["far", "angle-up"]}
															size="2x"
															className={"ml10px"}
														/>
													) : (
														<FontAwesomeIcon
															icon={["far", "angle-down"]}
															size="2x"
															className={"ml10px"}
														/>
													)}
													<motion.ul
														animate={
															isActive === index
																? "enter"
																: "exit"
														}
														initial="exit"
														variants={dropdown}
														className="dropdownList"
													>
														<ul className="mobile-children">
															{n.children.map((li, i) => {

																return (
																	<li
																		key={`mobile-child-${li.id}`}
																	>
																		<NavItem link={li} enableLine={n.children.length - 1 != i} />
																	</li>
																);
															})}
														</ul>
													</motion.ul>
												</Fragment>
											) : (
												<Fragment>
													<NavItem link={n} />
												</Fragment>
											)}
										</li>
									);
								})}

							</ul>
						</div>
					</div>
					<SearchModal
						searchOpen={searchOpen}
						close={() => {
							setSearchOpen(false);
						}}
					/>
				</Fragment>
			)}
		</SiteContext.Consumer>
	);
};

export default ProductHeader;
